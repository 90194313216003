// Generated by Framer (838580a)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import StatusPulse from "https://framerusercontent.com/modules/0Tnyq0i1MGPjEtIRog8t/5BV5V2nEcXHU7NyVRp43/WM8Gskec6.js";
import * as localizedValues from "./oBqDFVEYU-0.js";
const StatusPulseFonts = getFonts(StatusPulse);

const cycleOrder = ["d0moJFk3z", "wjC3lSvGN"];

const serializationHash = "framer-d5XR2"

const variantClassNames = {d0moJFk3z: "framer-v-4ebuiy", wjC3lSvGN: "framer-v-1wha3hf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Footer: "d0moJFk3z", Nav: "wjC3lSvGN"}

const getProps = ({height, hideStatus, id, width, ...props}) => { return {...props, AgMT4QNLZ: hideStatus ?? props.AgMT4QNLZ ?? true, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "d0moJFk3z"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hideStatus?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, fQoJEScDb, AgMT4QNLZ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "d0moJFk3z", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-4ebuiy", className, classNames)} data-framer-name={"Footer"} layoutDependency={layoutDependency} layoutId={"d0moJFk3z"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({wjC3lSvGN: {"data-framer-name": "Nav"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={getLocalizedValue("v0", activeLocale) ?? <React.Fragment><motion.p style={{"--font-selector": "R0Y7T3BlbiBTYW5zLTYwMA==", "--framer-font-family": "\"Open Sans\", \"Open Sans Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "600", "--framer-letter-spacing": "0px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(34, 34, 34))"}}>Staða kerfa</motion.p></React.Fragment>} className={"framer-1o08xa4"} fonts={["GF;Open Sans-600"]} layoutDependency={layoutDependency} layoutId={"iqfLnHSeV"} style={{"--extracted-r6o4lv": "rgb(34, 34, 34)"}} verticalAlignment={"top"} withExternalLayout/>{AgMT4QNLZ && (<ComponentViewportProvider width={"16px"}><motion.div className={"framer-1q9ea8i-container"} layoutDependency={layoutDependency} layoutId={"WS2GFpoAL-container"}><StatusPulse height={"100%"} id={"WS2GFpoAL"} layoutId={"WS2GFpoAL"} style={{height: "100%", width: "100%"}} variant={fQoJEScDb} width={"100%"}/></motion.div></ComponentViewportProvider>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-d5XR2.framer-1hjitfd, .framer-d5XR2 .framer-1hjitfd { display: block; }", ".framer-d5XR2.framer-4ebuiy { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 4px; height: min-content; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-d5XR2 .framer-1o08xa4 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-d5XR2 .framer-1q9ea8i-container { flex: none; height: 16px; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-d5XR2.framer-4ebuiy { gap: 0px; } .framer-d5XR2.framer-4ebuiy > * { margin: 0px; margin-left: calc(4px / 2); margin-right: calc(4px / 2); } .framer-d5XR2.framer-4ebuiy > :first-child { margin-left: 0px; } .framer-d5XR2.framer-4ebuiy > :last-child { margin-right: 0px; } }", ".framer-d5XR2.framer-v-1wha3hf .framer-1o08xa4 { order: 1; }", ".framer-d5XR2.framer-v-1wha3hf .framer-1q9ea8i-container { order: 0; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 17
 * @framerIntrinsicWidth 97
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"wjC3lSvGN":{"layout":["auto","auto"]}}}
 * @framerVariables {"AgMT4QNLZ":"hideStatus"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameroBqDFVEYU: React.ComponentType<Props> = withCSS(Component, css, "framer-d5XR2") as typeof Component;
export default FrameroBqDFVEYU;

FrameroBqDFVEYU.displayName = "Status/Status Menu item";

FrameroBqDFVEYU.defaultProps = {height: 17, width: 97};

addPropertyControls(FrameroBqDFVEYU, {variant: {options: ["d0moJFk3z", "wjC3lSvGN"], optionTitles: ["Footer", "Nav"], title: "Variant", type: ControlType.Enum}, AgMT4QNLZ: {defaultValue: true, title: "Hide status", type: ControlType.Boolean}} as any)

addFonts(FrameroBqDFVEYU, [{explicitInter: true, fonts: [{family: "Open Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4iY1M2xLER.woff2", weight: "600"}]}, ...StatusPulseFonts], {supportsExplicitInterCodegen: true})